/* Navbar Container */
.nav-container {
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .nav-container.light {
    background-color: #f5f5f5;
  }
  
  .nav-container.dark {
    background-color: #1f1f1f;
  }
  
  /* Navbar */
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    height: 60px;
    transition: all 0.3s ease;
  }
  
  /* Navigation Links Container */
  .nav-links {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  
  /* Navigation Links */
  .nav a {
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    transition: all 0.3s ease;
    white-space: nowrap;
    color: #000; /* Default color for light mode */
  }
  
  .nav-container.light .nav a {
    color: #150280; /* Light mode link color */
  }
  
  .nav-container.dark .nav a {
    color: #cebf3f; /* Dark mode link color */
  }
  
  /* Theme Toggle Button */
  .toggle-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
  }

  /* Responsive Design */
@media (max-width: 768px) {
    .nav {
      flex-direction: column;
      height: auto;
      padding: 1rem;
      gap: 1rem;
    }
  
    .nav-links {
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.75rem;
    }
  
    .nav a {
      font-size: 0.875rem;
      padding: 0.4rem 0.6rem;
    }
  
    .toggle-btn {
      padding: 0.4rem 0.8rem;
      font-size: 0.875rem;
    }

}

@media (max-width: 480px) {
    .nav-links {
      gap: 0.5rem;
    }
  
    .nav a {
      font-size: 0.8125rem;
      padding: 0.3rem 0.5rem;
    }
}