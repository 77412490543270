.timer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.timer-container {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 80%;
  max-width: 600px;
}

.timer-container.light {
  background-color: #ffffff;
  color: #000000;
}

.timer-container.dark {
  background-color: #121212;
  color: #ffffff;
}

.game-selector {
  margin-bottom: 20px;
}

.advanced-settings {
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.advanced-settings.light {
  background-color: #f9f9f9;
  color: #000000;
}

.advanced-settings.dark {
  background-color: #1e1e1e;
  color: #ffffff;
}

.advanced-settings label {
  display: block;
  margin-bottom: 10px;
}

.custom-settings {
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-settings.light {
  background-color: #f9f9f9;
  color: #000000;
}

.custom-settings.dark {
  background-color: #1e1e1e;
  color: #ffffff;
}

.custom-settings label {
  display: block;
  margin-bottom: 10px;
}

.timer-display {
  font-size: 48px;
  margin: 20px 0;
}

.controls button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.controls button:hover {
  background-color: #0056b3;
}

.controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.settings select,
.custom-settings select,
.advanced-settings select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.settings select:focus,
.custom-settings select:focus,
.advanced-settings select:focus {
  border-color: #007bff;
  outline: none;
}

.warning-sounds button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.warning-sounds button:hover {
  background-color: #c82333;
}

.warning-sounds button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
