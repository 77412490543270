/* Base Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  transition: all 0.3s ease;
}

/* Theme Colors */
body.light {
  background-color: #ffffff;
  color: #000000;
}

body.dark {
  background-color: #121212;
  color: #ffffff;
}

/* Page Container */
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  min-height: calc(100vh - 60px); /* Subtract navbar height */
  transition: all 0.3s ease;
}
.page-container.light {
  background-color: #ffffff;
  color: #000000;
}

.page-container.dark {
  background-color: #121212;
  color: #ffffff;
}

/* Global Theme Classes */
.light {
  background-color: #ffffff;
  color: #000000;
}

.dark {
  background-color: #121212;
  color: #ffffff;
}

/* Centering Wrapper */
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Responsive Design */
@media (max-width: 768px) {
  .page-container {
    padding: 1rem;
  }
}

/* Extra small devices */
@media (max-width: 480px) {
  .page-container {
    padding: 0.75rem;
  }
}